import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Button, Slide } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

import {
    entityLobbyingActivity,
    entityOrg,
    entityOverviews,
    entityRegistration,
} from '../../../interfaces/generic-entity.interface';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import DateSelection from '../../../components/misc/DateSelection';
import { COLORS } from '../../../constants/colors';
import DashTable from '../../../components/generic-dashboards/DashTable';
import { entityActivity } from '../../../interfaces/organization-entity.interface';
import Breakdowns from '../../../components/generic-dashboards/Breakdowns';
import Overview from '../../../components/generic-dashboards/Overview';
import { OrganizationDPOH } from '../../../tooltips/organizations/OrganizationDPOH';
import { OrganizationOverview } from '../../../tooltips/organizations/OrganizationOverview';
import { OrganizationCommunication } from '../../../tooltips/organizations/OrganizationCommunications';
import { OrganizationRegistration } from '../../../tooltips/organizations/OrganizationRegestrations';
import { OrganizationDeregistration } from '../../../tooltips/organizations/OrganizationDeregestrations';
import { OrganizationLobbyingActivity } from '../../../tooltips/organizations/OrganizationLobbyingActivity';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import { DashboardDownloadModal } from '../../../components/misc/DashboardDownloadModal';
import { useSelector } from 'react-redux';
import { GATrackDashboardLoad, EVENT_TYPES } from '../../../google_analytics/TrackEvent';
import { selectEmail, selectUserProductType } from '../../../store/reducers/userSlice';

interface dashboardProps {
    inactive?: boolean;

    sectorCode: string;
    orgName: string;
    date: string;
    handleDateChange: (x: string) => void;

    overview?: entityOverviews | null;
    overviewLoading?: boolean;
    overviewHeaders?: string[];
    overviewIndent?: Array<string>;

    recentLobbying: entityLobbyingActivity[] | null;
    recentLobbyingLoading: boolean;

    registrations: entityRegistration[] | null;
    registrationsLoading: boolean;

    deregistrations: entityRegistration[] | null;
    deregistrationsLoading: boolean;

    dpohActivity: entityActivity[] | null;
    dpohActivityLoading: boolean;

    mpActivity: entityActivity[] | null;
    mpActivityLoading: boolean;

    topInstitutions: entityOrg[] | null;
    topInstitutionsLoading: boolean;

    topSubjects: entityOrg[] | null;
    topSubjectsLoading: boolean;

    internalExternal: entityOrg[] | null;
    internalExternalLoading: boolean;

    exportLoading: boolean;
    exportFail: boolean;
}

/*
    Component: OrganizationDashboard.tsx
    Params: N/A
    Author: Will Brewer
    Desc: Page component for generic, non-custom dashboards
*/
const OrganizationDashboard = ({
    inactive = false,
    sectorCode,
    orgName,
    date,
    handleDateChange,
    overview = { yearly: [], monthly: [] },
    overviewLoading = false,
    overviewIndent = [],
    overviewHeaders = [],
    recentLobbying = [],
    recentLobbyingLoading = false,
    registrations = [],
    registrationsLoading = false,
    deregistrations = [],
    deregistrationsLoading = false,
    dpohActivity = [],
    dpohActivityLoading = false,
    mpActivity = [],
    mpActivityLoading = false,
    topInstitutions = [],
    topInstitutionsLoading = false,
    topSubjects = [],
    topSubjectsLoading = false,
    internalExternal = [],
    internalExternalLoading = false,
    exportLoading = false,
    exportFail = false,
}: dashboardProps) => {
    // Decide which color from MUI status color scheme to use for buttons
    const type_color = 'success';

    const overview_colors = COLORS[5];

    const [dateOpen, setDateOpen] = useState<boolean>(false);
    const [dateSet, setDateSet] = useState<boolean>(false);
    const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

    const handleDateOpen = () => setDateOpen(true);
    const handleDateClose = () => {
        setDateOpen(false);
        setDateSet(true);
    };

    const [scrollPosition, setScrollPosition] = useState(0);

    const location = useLocation();
    const sector_name: string | null = new URLSearchParams(location.search).get('sector');
    const entity_name: string | null = orgName;

    const userEmail = useSelector(selectEmail);
    const userProductType = useSelector(selectUserProductType);

    useEffect(() => {
        GATrackDashboardLoad(
            `${inactive ? 'Inactive Organizations' : 'Organizations'}`,
            `${entity_name}`,
            EVENT_TYPES.PAGE_LOAD,
            userEmail ? userEmail : undefined,
            userProductType ? userProductType : undefined
        );
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrollPosition(scrollPosition);
        };
        window.addEventListener('scroll', handleScroll);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [entity_name, inactive, userEmail, userProductType]);

    return (
        <div className="py-32 lg:px-20 px-4 grid grid-cols-12">
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={dateSet}
                onClose={() => setDateSet(false)}
                autoHideDuration={3000}
                message="Date Set"
            >
                <Alert onClose={() => setDateSet(false)} severity="success">
                    Date set to {date}
                </Alert>
            </Snackbar>

            <Slide direction="left" in={scrollPosition >= 50} mountOnEnter unmountOnExit>
                <div className="fixed z-20 bottom-24 right-5 gap-2 lg:top-20 lg:right-4 flex flex-col h-0">
                    <Button
                        className="group flex"
                        onClick={handleDateOpen}
                        variant="contained"
                        startIcon={<CalendarMonthRoundedIcon />}
                        color={type_color}
                        sx={{ backgroundColor: overview_colors.primary }}
                    >
                        {date}
                    </Button>
                    <Button
                        variant="contained"
                        color={type_color}
                        sx={{ backgroundColor: overview_colors.primary }}
                        startIcon={<SimCardDownloadRoundedIcon />}
                        onClick={() => setExportModalOpen(true)}
                    >
                        Save PDF
                    </Button>
                </div>
            </Slide>

            <DateSelection
                open={dateOpen}
                handleClose={handleDateClose}
                handleDateChange={handleDateChange}
                views={['year', 'month', 'day']}
            />

            <DashboardDownloadModal
                title={entity_name || 'Dashboard'}
                type={'organizations'}
                modalOpen={exportModalOpen}
                handleClose={() => setExportModalOpen(false)}
                overview={overview}
                lobbyingBreakdown={
                    topInstitutions && topSubjects && internalExternal
                        ? {
                              cluster_1: topInstitutions,
                              cluster_2: topSubjects,
                              cluster_3: internalExternal,
                          }
                        : null
                }
                recentLobbying={recentLobbying}
                dpohActivity={dpohActivity}
                registrations={registrations}
                deregistrations={deregistrations}
                exportLoading={exportLoading}
                exportFail={exportFail}
                inactive={inactive}
            />

            <div className="col-start-1 col-end-13 flex flex-row justify-between">
                <div>
                    <Breadcrumbs className="col-start-2 lg:col-end-12 col-end-12" separator=">">
                        <Link to="/" className="hover:underline">
                            Lobby<b>IQ</b>
                        </Link>
                        <Link to="/sectors" className="hover:underline">
                            Sectors
                        </Link>
                        <Link
                            to={`/sectors/${sectorCode}?name=${sector_name}`}
                            className="hover:underline"
                        >
                            {sector_name}
                        </Link>
                        <Link
                            to={`/sectors/${sectorCode}/organizations?sector=${sector_name}`}
                            className="hover:underline"
                        >
                            Organizations
                        </Link>
                        <div>{entity_name}</div>
                    </Breadcrumbs>
                </div>
                <Slide direction="left" in={true}>
                    <div className="lg:flex hidden flex-row gap-4">
                        <Button
                            onClick={handleDateOpen}
                            variant="outlined"
                            startIcon={<CalendarMonthRoundedIcon />}
                            color={type_color}
                            sx={{ color: overview_colors.primary }}
                        >
                            Data From: {date}
                        </Button>
                        <Button
                            variant="outlined"
                            color={type_color}
                            sx={{ color: overview_colors.primary }}
                            startIcon={<SimCardDownloadRoundedIcon />}
                            onClick={() => setExportModalOpen(true)}
                        >
                            Save PDF
                        </Button>
                    </div>
                </Slide>
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <Overview
                    dashboard={inactive ? 'Inactive Organizations' : 'Organizations'}
                    topic={entity_name}
                    title={`${entity_name} Overview ${inactive ? '(Inactive Organization)' : ''}`}
                    subtitle={entity_name}
                    tooltipContent={OrganizationOverview}
                    barChartTitle={`Lobbying Communications (${inactive ? '36' : '18'} months)`}
                    primaryColor={overview_colors.primary}
                    secondaryColor={overview_colors.secondary}
                    overviews={overview}
                    loading={overviewLoading}
                    tableHeader={overviewHeaders}
                    overviewIndent={overviewIndent}
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <Breakdowns
                    title="Lobbying Communications"
                    subtitle={`Past ${inactive ? '36' : '12'} Months`}
                    tooltipContent={OrganizationCommunication}
                    breakdownData1={topInstitutions}
                    loading1={topInstitutionsLoading}
                    breakdownData2={topSubjects}
                    loading2={topSubjectsLoading}
                    breakdownData3={internalExternal}
                    loading3={internalExternalLoading}
                />
            </div>

            <div className="mt-14 col-start-1 col-end-13">
                <DashTable
                    dashboard={inactive ? 'Inactive Organizations' : 'Organizations'}
                    topic={entity_name}
                    title={`${inactive ? '' : 'Recent'} Lobbying Activity`}
                    subtitle="Communication Reports"
                    tooltipContent={OrganizationLobbyingActivity}
                    tableData={recentLobbying}
                    loading={recentLobbyingLoading}
                    tableHeader={[
                        'date',
                        'organization',
                        'dpoh',
                        'title',
                        'institution',
                        'lobby_firm',
                        'consultant',
                        'Prior Comms (36M)',
                        'link',
                    ]}
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <DashTable
                    dashboard={inactive ? 'Inactive Organizations' : 'Organizations'}
                    topic={entity_name}
                    title="DPOHs Lobbied"
                    subtitle={`Past ${inactive ? '36' : '12'} Months`}
                    tooltipContent={OrganizationDPOH}
                    tableData={dpohActivity}
                    loading={dpohActivityLoading}
                    tableHeader={[
                        'name',
                        'title',
                        'institution',
                        'prior_comms_(12M)',
                        'prior_comms_(36M)',
                        'percent_comms_(12M)',
                    ]}
                    suffixes={{ 'percent_comms_(12M)': '%' }}
                    defaultSort="percent_comms_(12M)"
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <DashTable
                    dashboard={inactive ? 'Inactive Organizations' : 'Organizations'}
                    topic={entity_name}
                    title="MPs Lobbied"
                    subtitle={`Past ${inactive ? '36' : '12'} Months`}
                    tooltipContent={OrganizationDPOH}
                    tableData={mpActivity}
                    loading={mpActivityLoading}
                    tableHeader={[
                        'name',
                        'title',
                        'institution',
                        'prior_comms_(12M)',
                        'prior_comms_(36M)',
                        'percent_comms_(12M)',
                    ]}
                    suffixes={{ 'percent_comms_(12M)': '%' }}
                    defaultSort="percent_comms_(12M)"
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <DashTable
                    dashboard={inactive ? 'Inactive Organizations' : 'Organizations'}
                    topic={entity_name}
                    titleText="Active Registrations"
                    title={
                        <div className="flex flex-row items-center gap-2">
                            <HandshakeRoundedIcon color="info" />
                            Active Registrations
                        </div>
                    }
                    subtitle={`Past ${inactive ? '36' : '12'} Months`}
                    tooltipContent={OrganizationRegistration}
                    tableData={registrations}
                    loading={registrationsLoading}
                    tableHeader={[
                        'date',
                        'lobby_firm',
                        'province',
                        'consultant',
                        `Lobbying Reports (${inactive ? '36M' : '12M'})`,
                        'history',
                        'dashboard',
                    ]}
                    defaultSort={`Lobbying Reports (${inactive ? '36M' : '12M'})`}
                    defaultNumRows={10}
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <DashTable
                    dashboard={inactive ? 'Inactive Organizations' : 'Organizations'}
                    topic={entity_name}
                    titleText="Recent Deregistrations"
                    title={
                        <div className="flex flex-row items-center gap-2">
                            <HandshakeRoundedIcon color="error" />
                            Recent Deregistrations
                        </div>
                    }
                    subtitle={`Past ${inactive ? '36' : '12'} Months`}
                    tooltipContent={OrganizationDeregistration}
                    tableData={deregistrations}
                    loading={deregistrationsLoading}
                    tableHeader={[
                        'date',
                        'lobby_firm',
                        'province',
                        'consultant',
                        `Lobbying Reports (${inactive ? '36M' : '12M'})`,
                        'history',
                        'dashboard',
                    ]}
                    defaultSort={`Lobbying Reports (${inactive ? '36M' : '12M'})`}
                    defaultNumRows={10}
                />
            </div>
        </div>
    );
};

export default OrganizationDashboard;

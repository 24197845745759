import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Button, Slide } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuOpen from '@mui/icons-material/MenuOpenRounded';
import { replace_underscores_capitalize } from '../../../helpers/string_formatters';

import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';

import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import DateSelection from '../../../components/misc/DateSelection';
import { COLORS } from '../../../constants/colors';
import ModalIssueListDataLayer from '../data/ModalIssueListDataLayer';
import { bumperSuperList } from '../../../interfaces/bumper-types.interface';
import { useSelector } from 'react-redux';
import { GATrackDashboardLoad, EVENT_TYPES } from '../../../google_analytics/TrackEvent';
import { selectEmail, selectUserProductType } from '../../../store/reducers/userSlice';
import DashTable from '../../../components/generic-dashboards/DashTable';
import {
    entityCluster,
    entityLobbyingActivity,
    entityOverviews,
    entityRegistration,
} from '../../../interfaces/generic-entity.interface';
import {
    IssueActivity,
    IssueDeregistrations,
    IssueOverviewTooltip,
    IssueRegistrations,
} from '../properties/Tooltips';
import Cluster from '../../../components/generic-dashboards/Cluster';
import {
    governmentAnnouncement,
    issueInfo,
    keywordMentions,
    Tweet,
} from '../interface/issue-entity.interface';
import KeywordComposition from './KeywordComposition';
import IssueDashTable from './IssueDashTable';
import { DashboardDownloadModal } from '../../../components/misc/DashboardDownloadModal';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import IssueUpdateTable from '../../../components/whats-new/IssueUpdateTable';
import IssueOverview from './IssueOverview';

interface clusterProps {
    cluster_data: entityCluster | null;
    title: string;
    tooltipContent: JSX.Element;
    loading: boolean;
}

interface dashboardProps {
    date: string;
    handleDateChange: (x: string) => void;
    info: issueInfo | null;
    infoLoading: boolean;
    announcements: governmentAnnouncement[] | null;
    announcementsLoading: boolean;
    updates: any | null;
    updatesLoading: boolean;
    tweets: Tweet[] | null;
    tweetsLoading: boolean;
    keywordMentions: keywordMentions[] | null;
    keywordMentionsLoading: boolean;
    overview: entityOverviews | null;
    overviewLoading: boolean;
    socialOverview: any[] | null;
    socialOverviewLoading: boolean;
    recentLobbying: entityLobbyingActivity[] | null;
    recentLobbyingLoading: boolean;
    recentRegistrations: entityRegistration[] | null;
    recentRegistrationsLoading: boolean;
    recentDeregistrations: entityRegistration[] | null;
    recentDeregistrationsLoading: boolean;
    clusters: clusterProps[];
    bumperData: bumperSuperList[] | null;
    bumperDataLoading: boolean;
    exportLoading: boolean;
    exportFail: boolean;
}

/*
    Component: IssueDashboard.tsx
    Params: N/A
    Author: Will Brewer
    Desc: Page component for generic, non-custom dashboards
*/
const IssueDashboard = ({
    date,
    handleDateChange,
    info = null,
    infoLoading = false,
    announcements = [],
    announcementsLoading = false,
    updates = null,
    updatesLoading = false,
    tweets = [],
    tweetsLoading,
    keywordMentions = null,
    keywordMentionsLoading = false,
    overview = {
        yearly: [],
        monthly: [],
    },
    overviewLoading = false,
    socialOverview = null,
    socialOverviewLoading = false,
    recentLobbying = [],
    recentLobbyingLoading = false,
    recentRegistrations = [],
    recentRegistrationsLoading = false,
    recentDeregistrations = [],
    recentDeregistrationsLoading = false,
    clusters,
    bumperData = [],
    bumperDataLoading = false,
    exportLoading = false,
    exportFail = false,
}: dashboardProps) => {
    const type = 'issues';

    // Decide which color from MUI status color scheme to use for buttons
    const type_color = 'error';

    const overview_colors = COLORS[3];

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [dateOpen, setDateOpen] = useState<boolean>(false);
    const [dateSet, setDateSet] = useState<boolean>(false);
    const [exportModalOpen, setExportModalOpen] = useState<boolean>(false);

    const handleOpen = () => setModalOpen(true);
    const handleClose = () => setModalOpen(false);

    const handleDateOpen = () => setDateOpen(true);
    const handleDateClose = () => {
        setDateOpen(false);
        setDateSet(true);
    };

    const [scrollPosition, setScrollPosition] = useState(0);

    const location = useLocation();
    const entity_name: string | null = new URLSearchParams(location.search).get('name');

    const userEmail = useSelector(selectEmail);
    const userProductType = useSelector(selectUserProductType);

    useEffect(() => {
        GATrackDashboardLoad(
            `${replace_underscores_capitalize(type)}`,
            `${entity_name}`,
            EVENT_TYPES.PAGE_LOAD,
            userEmail ? userEmail : undefined,
            userProductType ? userProductType : undefined
        );
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            setScrollPosition(scrollPosition);
        };
        window.addEventListener('scroll', handleScroll);
        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [entity_name, userEmail, userProductType]);

    return (
        <div className="py-32 lg:px-20 px-4 grid grid-cols-12">
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={dateSet}
                onClose={() => setDateSet(false)}
                autoHideDuration={3000}
                message="Date Set"
            >
                <Alert onClose={() => setDateSet(false)} severity="success">
                    Date set to {date}
                </Alert>
            </Snackbar>

            <Slide direction="left" in={scrollPosition >= 50} mountOnEnter unmountOnExit>
                <div className="fixed z-20 bottom-36 right-5 gap-2 lg:top-20 lg:right-4 flex flex-col h-0">
                    <Button
                        className="group flex"
                        onClick={handleDateOpen}
                        variant="contained"
                        startIcon={<CalendarMonthRoundedIcon />}
                        color={type_color}
                        sx={{ backgroundColor: overview_colors.primary }}
                    >
                        {date}
                    </Button>
                    <Button
                        onClick={handleOpen}
                        variant="contained"
                        startIcon={<MenuOpen />}
                        color={type_color}
                        sx={{ backgroundColor: overview_colors.primary }}
                    >
                        {replace_underscores_capitalize(type)}
                    </Button>
                    <Button
                        variant="contained"
                        color={type_color}
                        sx={{ backgroundColor: overview_colors.primary }}
                        startIcon={<SimCardDownloadRoundedIcon />}
                        onClick={() => setExportModalOpen(true)}
                    >
                        Save PDF
                    </Button>
                </div>
            </Slide>

            <ModalIssueListDataLayer open={modalOpen} handleClose={handleClose} />

            <DateSelection
                open={dateOpen}
                handleClose={handleDateClose}
                handleDateChange={handleDateChange}
                views={['year', 'month', 'day']}
            />

            <DashboardDownloadModal
                title={entity_name || 'Dashboard'}
                type={type}
                modalOpen={exportModalOpen}
                handleClose={() => setExportModalOpen(false)}
                updates={updates}
                overview={overview}
                keywordMentions={keywordMentions}
                keywordComposition={info}
                recentLobbying={recentLobbying}
                recentRegistrations={recentRegistrations}
                recentDeregistrations={recentDeregistrations}
                mpTweets={tweets}
                mpMentions={clusters[0].cluster_data}
                sectors={clusters[1].cluster_data}
                organizations={clusters[2].cluster_data}
                institutions={clusters[3].cluster_data}
                publicOfficeHolders={clusters[4].cluster_data}
                subjects={clusters[5].cluster_data}
                lobbyFirms={clusters[6].cluster_data}
                keywordNarrative={bumperData}
                exportLoading={exportLoading}
                exportFail={exportFail}
            />

            <div className="col-start-1 col-end-13 flex flex-row justify-between">
                <div>
                    <Breadcrumbs className="col-start-2 lg:col-end-12 col-end-12" separator=">">
                        <Link to="/" className="hover:underline">
                            Lobby<b>IQ</b>
                        </Link>
                        <Link to={`/${type}`} className="hover:underline">
                            {replace_underscores_capitalize(type)}
                        </Link>
                        <div>{entity_name}</div>
                    </Breadcrumbs>
                </div>
                <Slide direction="left" in={true}>
                    <div className="lg:flex hidden flex-row gap-4">
                        <Button
                            onClick={handleDateOpen}
                            variant="outlined"
                            startIcon={<CalendarMonthRoundedIcon />}
                            color={type_color}
                            sx={{ color: overview_colors.primary }}
                        >
                            Data From: {date}
                        </Button>
                        <Button
                            onClick={handleOpen}
                            variant="outlined"
                            startIcon={<MenuOpen />}
                            color={type_color}
                            sx={{ color: overview_colors.primary }}
                        >
                            {replace_underscores_capitalize(type)}
                        </Button>
                        <Button
                            variant="outlined"
                            color={type_color}
                            sx={{ color: overview_colors.primary }}
                            startIcon={<SimCardDownloadRoundedIcon />}
                            onClick={() => setExportModalOpen(true)}
                        >
                            Save PDF
                        </Button>
                    </div>
                </Slide>
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <KeywordComposition
                    title={entity_name ?? 'Issue Composition'}
                    subtitle=""
                    keywordData={info}
                    loading={infoLoading}
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <IssueUpdateTable
                    data={updates}
                    loading={updatesLoading}
                    name={entity_name ?? 'issue'}
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <DashTable
                    dashboard={`${replace_underscores_capitalize(type)}`}
                    topic={`${entity_name}`}
                    title={`Government Announcements Related to ${entity_name}`}
                    tableData={announcements}
                    loading={announcementsLoading}
                    tableHeader={['date', 'institution', 'title']}
                    defaultNumRows={5}
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <IssueOverview
                    dashboard={`${replace_underscores_capitalize(type)}`}
                    topic={`${entity_name}`}
                    title="Issue Salience"
                    subtitle={entity_name}
                    tooltipContent={IssueOverviewTooltip}
                    lobbyingOverview={overview ? overview.monthly : null}
                    lobbyingOverviewloading={overviewLoading}
                    mentionOverview={keywordMentions}
                    mentionOverviewloading={keywordMentionsLoading}
                    socialOverview={socialOverview}
                    socialOverviewloading={socialOverviewLoading}
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <DashTable
                    dashboard={`${replace_underscores_capitalize(type)}`}
                    topic={`${entity_name}`}
                    title="MP and Senator Social Media on Issue"
                    subtitle="Past 3 months"
                    tableData={tweets}
                    loading={tweetsLoading}
                    tableHeader={['date', 'name', 'affiliation', 'tweet']}
                    defaultNumRows={10}
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <IssueDashTable
                    dashboard={`${replace_underscores_capitalize(type)}`}
                    topic={`${entity_name}`}
                    title="Mentions of Issue Keywords in Hansard and Committee Meetings"
                    subtitle="Past 2 Months"
                    tableData={bumperData}
                    loading={bumperDataLoading}
                    defaultNumRows={5}
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <DashTable
                    dashboard={`${replace_underscores_capitalize(type)}`}
                    topic={`${entity_name}`}
                    title="Recent Lobbying on Issue"
                    subtitle="Communication Reports (3 months)"
                    tableData={recentLobbying}
                    loading={recentLobbyingLoading}
                    tableHeader={[
                        'date',
                        'organization',
                        'dpoh',
                        'institution',
                        'sector',
                        'lobby_firm',
                        'consultant',
                        'Prior Comms (36M)',
                        'link',
                    ]}
                    tooltipContent={IssueActivity}
                    defaultNumRows={10}
                />
            </div>

            {clusters
                .filter((cluster: any) => cluster.title === 'MP Mentions of issue')
                .map((cluster, idx) => (
                    <div key={idx} className="mt-16 col-start-1 col-end-13">
                        <Cluster
                            dashboard={`${replace_underscores_capitalize(type)}`}
                            topic={`${entity_name}`}
                            title={cluster.title}
                            subtitle="Number of Communication Reports"
                            date={date}
                            clusterData={cluster.cluster_data}
                            tooltipContent={cluster.tooltipContent}
                            clusterLoading={cluster.loading}
                        />
                    </div>
                ))}

            <div className="mt-16 col-start-1 col-end-13">
                <DashTable
                    dashboard={`${replace_underscores_capitalize(type)}`}
                    topic={`${entity_name}`}
                    title="Recent Lobbying Registrations with Issue Keywords"
                    tableData={recentRegistrations}
                    loading={recentRegistrationsLoading}
                    tableHeader={[
                        'date',
                        'organization',
                        'province',
                        'lobby_firm',
                        'consultant',
                        'history',
                        'link',
                    ]}
                    tooltipContent={IssueRegistrations}
                    defaultNumRows={10}
                />
            </div>

            <div className="mt-16 col-start-1 col-end-13">
                <DashTable
                    dashboard={`${replace_underscores_capitalize(type)}`}
                    topic={`${entity_name}`}
                    title="Recent Lobbying Deregistrations"
                    tableData={recentDeregistrations}
                    loading={recentDeregistrationsLoading}
                    tableHeader={[
                        'date',
                        'organization',
                        'province',
                        'lobby_firm',
                        'consultant',
                        'link',
                    ]}
                    tooltipContent={IssueDeregistrations}
                    defaultNumRows={10}
                />
            </div>

            {clusters
                .filter((cluster: any) => cluster.title !== 'MP Mentions of issue')
                .map((cluster, idx) => (
                    <div key={idx} className="mt-16 col-start-1 col-end-13">
                        <Cluster
                            dashboard={`${replace_underscores_capitalize(type)}`}
                            topic={`${entity_name}`}
                            title={cluster.title}
                            subtitle="Number of Communication Reports"
                            date={date}
                            clusterData={cluster.cluster_data}
                            tooltipContent={cluster.tooltipContent}
                            clusterLoading={cluster.loading}
                        />
                    </div>
                ))}
        </div>
    );
};

export default IssueDashboard;

import { Link } from 'react-router-dom';
import MetaTag from '../../components/metatag-component/MetaTag';
const ResearchHub = () => {
    return (
        <div className="xl:mx-24 mx-8 ">
            <MetaTag
                title="Research Hub"
                keywords="custom lobbying data, Wayback Machine,PDF Generator,Key Phrase Extraction,Issue Landscapes,Download Custom Lobbying Data, Flexible Queries, Sector, DPOH, AI-Enabled Text-Mining, Government Text Analysis, Legal Text Analysis, Regulatory Text Analysis, Wayback Machine, Historical Data Research, PDF Generator, Presentation-Ready Charts, Presentation-Ready Tables, Key Phrase Extraction, Machine Learning Algorithms, Critical Lobbying Issues, Issue Landscapes, Factor Analysis, Principal Component Analysis, GR Landscape"
                description="Download Custom Lobbying Data with flexible queries by Sector and DPOH. Leverage AI-enabled text-mining to analyze government texts. Research historical changes with our Wayback Machine. Generate presentation-ready charts and tables. Extract key phrases and create issue landscapes using advanced machine-learning algorithms."
            />
            <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>

            <div className="lg:grid grid-cols-12 grid-rows-auto gap-6 py-8 flex flex-col">
                <h1 className="lg:text-4xl text-3xl col-span-12 row-start-1 text-left">
                    Research Hub
                </h1>

                <div className="lg:col-start-1 row-start-2 col-start-2 col-end-7 grid grid-cols-12 gap-4">
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between max-h-[20rem]">
                        <div className="overflow-hidden">
                            <div className="text-2xl">
                                Download Custom{' '}
                                <span className="text-liq-ultramarine hover:underline">
                                    <Link to="/custom-query">Lobbying Data</Link>
                                </span>
                            </div>
                            <div className="text-md mt-8 font-light ">
                                Flexible custom queries to download all communications or
                                registrations by any desired data-angle, e.g. by Sector and DPOH
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between max-h-[20rem]">
                        <div className="overflow-hidden">
                            <div className="text-2xl">On Demand AI-Enabled Text-Mining</div>
                            <div className="text-md mt-8 font-light">
                                Use our AI-enabled tech-stack to analyze and summarize any kind of
                                government texts (legal, regulatory etc)
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between max-h-[20rem]">
                        <div className="overflow-hidden">
                            <div className="text-2xl">Wayback Machine</div>
                            <div className="text-md mt-8 font-light ">
                                Research changes: with our Wayback machine you can re-populate any
                                dashboard to he current state of affairs at any time since 2010
                            </div>
                        </div>
                    </div>
                    <div className="lg:col-span-6 col-span-12 bg-white shadow-md p-6 rounded-xl flex flex-col justify-between max-h-[20rem]">
                        <div className="overflow-hidden">
                            <div className="text-2xl">PDF Generator</div>
                            <div className="text-md mt-8 font-light">
                                Create presentation-ready charts and tables for presentations and
                                handouts.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-start-7 row-start-2 col-span-6">
                    <a href="https://www.dropbox.com/scl/fi/pfh8tvcnyh4zjiha7x8q0/TRAN_2024-02-27.pdf?rlkey=v0ys7pa25zdzwhd7lqo7zcpfu&e=1&dl=0">
                        <img
                            src={process.env.PUBLIC_URL + '/Custom_Query.png'}
                            className="w-full bg-slate-50 border border-slate-100 p-6 rounded-xl shadow-md	"
                            height="auto"
                            alt="logo"
                        />
                    </a>
                </div>
                <div className="col-12 md:col-7 lg:col-6 py-14 lg:gap-12 gap-y-8 lg:gap-y-0"></div>
            </div>
        </div>
    );
};

export default ResearchHub;

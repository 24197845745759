import { Tweet } from '../features/issue-dashboards/interface/issue-entity.interface';

/*

OUR COMMONS COMMITTEE MEETING LINKS
Generate a link to our commons document evidence given meeting number, parliament, session, and committee abbrev

*/
export const getCommitteeMeetingLink = (
    cmte_abbr: string,
    parliament: number,
    session: number,
    meetingNo: number
): string =>
    `https://www.ourcommons.ca/DocumentViewer/en/${parliament}-${session}/${cmte_abbr}/meeting-${meetingNo}/notice`;

export const committeeMeetingLinkFormatter = (
    cmte_abbr: string,
    parliament: number,
    session: number,
    meetingNo: number,
    str: string
): string => `URL#${str}#${getCommitteeMeetingLink(cmte_abbr, parliament, session, meetingNo)}`;

export const senateCommitteeMeetingLinkFormatter = (link: string, str: string): string =>
    `URL#${str}#${link}`;

export const govAnnouncementLinkFormatter = (link: string, str: string): string =>
    `URL#${str}#${link}`;

/*
TWITTER LINKS
*/
const getTwitterLink = (tweet: Tweet): string =>
    `https://twitter.com/${tweet.twitter_handle}/status/${tweet.tweet_id}`;

export const twitterLinkFormatter = (tweet: Tweet): string =>
    `URL#${tweet.tweet}#${getTwitterLink(tweet)}`;
